export let BASE_PUBLIC_URL: string = "https://aprendamk-api.eventials.com"
export let BASE_PRIVATE_URL: string = "https://jmno1bzgtc.execute-api.us-east-1.amazonaws.com/prd/"

/* const ENV = (window.location.href.split("/")[2] === "localhost:3000" || window.location.href.split("/")[2] === "stg-aprendamk.eventials.com") ? 
"staging" : "production";
 */
/* if (ENV === "staging") {
  // BASE_PUBLIC_URL = "https://stg-aprendamk.eventials.com"
  BASE_PUBLIC_URL = "https://6r9ropu6z6.execute-api.us-east-2.amazonaws.com/stg/"
  BASE_PRIVATE_URL = "https://jmno1bzgtc.execute-api.us-east-1.amazonaws.com/prd/"
} else { */
  BASE_PUBLIC_URL = "https://aprendamk-api.eventials.com"
  BASE_PRIVATE_URL = "https://jmno1bzgtc.execute-api.us-east-1.amazonaws.com/prd/"

/* } */

export const ZOOM_API_KEY = "2fl04EbZRseynvITbreR6Q";

export enum FetchTypes {
  GET = "get",
  POST = "post",
}

export const evtsFetch = async (
  method: FetchTypes,
  event: string,
  body: null | any,
  isPublic: boolean = true
): Promise<any | null> => {
  let _url: URL = new URL(`${BASE_PUBLIC_URL}`);

  if (!isPublic) {
    _url = new URL(`${BASE_PRIVATE_URL}`);
  }

  if (event) {
    _url.pathname = `${_url.pathname}${event}`;
  }

  try {
    const response = await fetch(_url.toString(), {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: !!body ? JSON.stringify(body) : undefined,
    });

    if (response.status === 204) return response.status;
    if (response.status === 404) return response.status;
    if (response.status !== 200) return null;
    const jsonData = await response.json();
    return jsonData || null;
  } catch (error) {
    return error;
  }
};

export const fetchEvent = async (
  event: string,
  body: null | any,
  isPublic: boolean = true,
): Promise<any | null> => {
  let _url: URL = new URL(`${BASE_PUBLIC_URL}`);

  if (!isPublic) {
    _url = new URL(`${BASE_PRIVATE_URL}`);
  }

  if (event) {
/*     if(ENV === "staging") {
      _url.pathname = `${_url.pathname}/${body.owner}/${body.slug}/webinar`;
    } 
    else { */
      _url.pathname = `${_url.pathname}${event}`;
/*     } */
  }

  const method = /* ENV === "staging" ? "GET" :  */"POST";
  const bodyReq = /* ENV === "staging" ? undefined :  */JSON.stringify(body);

  try {
    const response = await fetch(_url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyReq
    });

    if (response.status !== 200) return null;
    const jsonData = await response.json();
    return jsonData || null;
  } catch (error) {
    console.log(error)
    return null;
  }
};
